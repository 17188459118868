@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply cursor-default;
}

a:hover {
    @apply cursor-pointer;
}

@layer base {
  @font-face {
    font-family: 'Habbo';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/habbo.ttf') format('truetype');
  }
}

.filter-blur {
  filter: url(#blur-and-scale);
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  z-index: -1;
  opacity: 0.05;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #000000;
  overflow: hidden;
}

.background span {
  width: 1vmin;
  height: 1vmin;
  border-radius: 1vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 28;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #ffffff;
  top: 54%;
  left: 64%;
  animation-duration: 78s;
  animation-delay: -200s;
  transform-origin: -12vw -1vh;
  box-shadow: 2vmin 0 0.9587979311764228vmin currentColor;
}
.background span:nth-child(1) {
  color: #ffffff;
  top: 45%;
  left: 44%;
  animation-duration: 93s;
  animation-delay: -27s;
  transform-origin: 4vw -9vh;
  box-shadow: -2vmin 0 1.0683157524641465vmin currentColor;
}
.background span:nth-child(2) {
  color: #ffffff;
  top: 90%;
  left: 72%;
  animation-duration: 146s;
  animation-delay: -34s;
  transform-origin: 15vw 21vh;
  box-shadow: -2vmin 0 0.9637884624743511vmin currentColor;
}
.background span:nth-child(3) {
  color: #ffffff;
  top: 49%;
  left: 15%;
  animation-duration: 164s;
  animation-delay: -179s;
  transform-origin: 16vw -1vh;
  box-shadow: -2vmin 0 0.5124844257088048vmin currentColor;
}
.background span:nth-child(4) {
  color: #ffffff;
  top: 99%;
  left: 64%;
  animation-duration: 124s;
  animation-delay: -137s;
  transform-origin: 6vw -3vh;
  box-shadow: -2vmin 0 0.8473717486929107vmin currentColor;
}
.background span:nth-child(5) {
  color: #ffffff;
  top: 26%;
  left: 100%;
  animation-duration: 100s;
  animation-delay: -191s;
  transform-origin: 3vw -19vh;
  box-shadow: -2vmin 0 0.3756059058394492vmin currentColor;
}
.background span:nth-child(6) {
  color: #ffffff;
  top: 36%;
  left: 16%;
  animation-duration: 35s;
  animation-delay: -200s;
  transform-origin: -10vw 15vh;
  box-shadow: -2vmin 0 0.26059037699760546vmin currentColor;
}
.background span:nth-child(7) {
  color: #ffffff;
  top: 24%;
  left: 5%;
  animation-duration: 148s;
  animation-delay: -24s;
  transform-origin: 8vw -21vh;
  box-shadow: 2vmin 0 0.7599461321237462vmin currentColor;
}
.background span:nth-child(8) {
  color: #ffffff;
  top: 72%;
  left: 40%;
  animation-duration: 209s;
  animation-delay: -19s;
  transform-origin: -23vw -14vh;
  box-shadow: -2vmin 0 0.28150215332218154vmin currentColor;
}
.background span:nth-child(9) {
  color: #ffffff;
  top: 84%;
  left: 6%;
  animation-duration: 36s;
  animation-delay: -59s;
  transform-origin: 9vw 25vh;
  box-shadow: -2vmin 0 0.8287999345111066vmin currentColor;
}
.background span:nth-child(10) {
  color: #ffffff;
  top: 1%;
  left: 14%;
  animation-duration: 117s;
  animation-delay: -99s;
  transform-origin: 9vw -21vh;
  box-shadow: -2vmin 0 1.110464262628107vmin currentColor;
}
.background span:nth-child(11) {
  color: #ffffff;
  top: 54%;
  left: 22%;
  animation-duration: 147s;
  animation-delay: -80s;
  transform-origin: -24vw 13vh;
  box-shadow: 2vmin 0 1.1259521677394624vmin currentColor;
}
.background span:nth-child(12) {
  color: #ffffff;
  top: 59%;
  left: 69%;
  animation-duration: 147s;
  animation-delay: -168s;
  transform-origin: -10vw -17vh;
  box-shadow: -2vmin 0 1.0344304614082056vmin currentColor;
}
.background span:nth-child(13) {
  color: #ffffff;
  top: 4%;
  left: 54%;
  animation-duration: 206s;
  animation-delay: -175s;
  transform-origin: 15vw 11vh;
  box-shadow: -2vmin 0 1.2070017477112727vmin currentColor;
}
.background span:nth-child(14) {
  color: #ffffff;
  top: 14%;
  left: 76%;
  animation-duration: 96s;
  animation-delay: -215s;
  transform-origin: -16vw 14vh;
  box-shadow: 2vmin 0 1.20005501665104vmin currentColor;
}
.background span:nth-child(15) {
  color: #ffffff;
  top: 64%;
  left: 87%;
  animation-duration: 22s;
  animation-delay: -210s;
  transform-origin: -1vw -19vh;
  box-shadow: 2vmin 0 0.7325011596448743vmin currentColor;
}
.background span:nth-child(16) {
  color: #ffffff;
  top: 25%;
  left: 2%;
  animation-duration: 206s;
  animation-delay: -122s;
  transform-origin: -12vw 6vh;
  box-shadow: -2vmin 0 0.3974958649616467vmin currentColor;
}
.background span:nth-child(17) {
  color: #ffffff;
  top: 15%;
  left: 98%;
  animation-duration: 146s;
  animation-delay: -160s;
  transform-origin: -22vw 3vh;
  box-shadow: 2vmin 0 0.5719585207553286vmin currentColor;
}
.background span:nth-child(18) {
  color: #ffffff;
  top: 87%;
  left: 88%;
  animation-duration: 172s;
  animation-delay: -124s;
  transform-origin: 22vw -5vh;
  box-shadow: -2vmin 0 0.8974596474349645vmin currentColor;
}
.background span:nth-child(19) {
  color: #ffffff;
  top: 94%;
  left: 66%;
  animation-duration: 182s;
  animation-delay: -220s;
  transform-origin: -8vw 21vh;
  box-shadow: 2vmin 0 0.44781571713845514vmin currentColor;
}
.background span:nth-child(20) {
  color: #ffffff;
  top: 25%;
  left: 38%;
  animation-duration: 100s;
  animation-delay: -171s;
  transform-origin: 16vw -12vh;
  box-shadow: 2vmin 0 0.7977578650155923vmin currentColor;
}
.background span:nth-child(21) {
  color: #ffffff;
  top: 28%;
  left: 84%;
  animation-duration: 106s;
  animation-delay: -130s;
  transform-origin: -1vw 10vh;
  box-shadow: 2vmin 0 0.38767217590053815vmin currentColor;
}
.background span:nth-child(22) {
  color: #ffffff;
  top: 56%;
  left: 38%;
  animation-duration: 139s;
  animation-delay: -117s;
  transform-origin: -9vw -7vh;
  box-shadow: -2vmin 0 0.3381928330964967vmin currentColor;
}
.background span:nth-child(23) {
  color: #ffffff;
  top: 85%;
  left: 96%;
  animation-duration: 139s;
  animation-delay: -170s;
  transform-origin: -5vw -19vh;
  box-shadow: -2vmin 0 0.5261642003555527vmin currentColor;
}
.background span:nth-child(24) {
  color: #ffffff;
  top: 60%;
  left: 71%;
  animation-duration: 176s;
  animation-delay: -218s;
  transform-origin: -16vw -16vh;
  box-shadow: 2vmin 0 0.9514968038318785vmin currentColor;
}
.background span:nth-child(26) {
  color: #ffffff;
  top: 77%;
  left: 92%;
  animation-duration: 63s;
  animation-delay: -51s;
  transform-origin: -2vw 9vh;
  box-shadow: -2vmin 0 0.3532712513560827vmin currentColor;
}
.background span:nth-child(27) {
  color: #ffffff;
  top: 88%;
  left: 49%;
  animation-duration: 196s;
  animation-delay: -22s;
  transform-origin: 12vw 3vh;
  box-shadow: -2vmin 0 1.2476539364468082vmin currentColor;
}
.background span:nth-child(28) {
  color: #ffffff;
  top: 44%;
  left: 55%;
  animation-duration: 127s;
  animation-delay: -20s;
  transform-origin: -13vw 11vh;
  box-shadow: 2vmin 0 0.6933636659641851vmin currentColor;
}
.background span:nth-child(29) {
  color: #ffffff;
  top: 53%;
  left: 49%;
  animation-duration: 106s;
  animation-delay: -121s;
  transform-origin: 10vw 16vh;
  box-shadow: 2vmin 0 0.31413441104854245vmin currentColor;
}
.background span:nth-child(30) {
  color: #ffffff;
  top: 49%;
  left: 25%;
  animation-duration: 21s;
  animation-delay: -49s;
  transform-origin: -24vw 15vh;
  box-shadow: -2vmin 0 0.8259051264457355vmin currentColor;
}
.background span:nth-child(31) {
  color: #ffffff;
  top: 87%;
  left: 1%;
  animation-duration: 11s;
  animation-delay: -142s;
  transform-origin: 19vw -4vh;
  box-shadow: -2vmin 0 0.9031580839056612vmin currentColor;
}
.background span:nth-child(32) {
  color: #ffffff;
  top: 75%;
  left: 52%;
  animation-duration: 96s;
  animation-delay: -143s;
  transform-origin: 18vw -1vh;
  box-shadow: 2vmin 0 0.32542964656727724vmin currentColor;
}
.background span:nth-child(33) {
  color: #ffffff;
  top: 51%;
  left: 23%;
  animation-duration: 155s;
  animation-delay: -129s;
  transform-origin: 13vw 9vh;
  box-shadow: -2vmin 0 1.1071056615318975vmin currentColor;
}
.background span:nth-child(34) {
  color: #ffffff;
  top: 98%;
  left: 49%;
  animation-duration: 33s;
  animation-delay: -5s;
  transform-origin: -14vw 2vh;
  box-shadow: -2vmin 0 0.48963958084359027vmin currentColor;
}
.background span:nth-child(35) {
  color: #ffffff;
  top: 42%;
  left: 82%;
  animation-duration: 93s;
  animation-delay: -179s;
  transform-origin: 24vw -18vh;
  box-shadow: 2vmin 0 0.7182794366119676vmin currentColor;
}
.background span:nth-child(36) {
  color: #ffffff;
  top: 20%;
  left: 91%;
  animation-duration: 202s;
  animation-delay: -193s;
  transform-origin: 7vw -18vh;
  box-shadow: 2vmin 0 0.3555393997195804vmin currentColor;
}
.background span:nth-child(37) {
  color: #ffffff;
  top: 1%;
  left: 20%;
  animation-duration: 218s;
  animation-delay: -104s;
  transform-origin: 7vw -18vh;
  box-shadow: -2vmin 0 1.1401002860923923vmin currentColor;
}
.background span:nth-child(38) {
  color: #ffffff;
  top: 56%;
  left: 33%;
  animation-duration: 124s;
  animation-delay: -32s;
  transform-origin: -7vw 12vh;
  box-shadow: -2vmin 0 1.0592242637770155vmin currentColor;
}
.background span:nth-child(39) {
  color: #ffffff;
  top: 36%;
  left: 97%;
  animation-duration: 91s;
  animation-delay: -29s;
  transform-origin: 20vw 2vh;
  box-shadow: 2vmin 0 0.34173386735530675vmin currentColor;
}
.background span:nth-child(40) {
  color: #ffffff;
  top: 84%;
  left: 77%;
  animation-duration: 141s;
  animation-delay: -35s;
  transform-origin: 8vw -23vh;
  box-shadow: -2vmin 0 0.9789614793891899vmin currentColor;
}
.background span:nth-child(41) {
  color: #ffffff;
  top: 84%;
  left: 36%;
  animation-duration: 86s;
  animation-delay: -220s;
  transform-origin: 16vw 14vh;
  box-shadow: -2vmin 0 1.2247263119653853vmin currentColor;
}
.background span:nth-child(42) {
  color: #ffffff;
  top: 19%;
  left: 82%;
  animation-duration: 75s;
  animation-delay: -12s;
  transform-origin: 17vw -11vh;
  box-shadow: -2vmin 0 0.7558704712734421vmin currentColor;
}
.background span:nth-child(43) {
  color: #ffffff;
  top: 33%;
  left: 10%;
  animation-duration: 86s;
  animation-delay: -48s;
  transform-origin: -2vw -15vh;
  box-shadow: -2vmin 0 1.0269294498589274vmin currentColor;
}
.background span:nth-child(44) {
  color: #ffffff;
  top: 24%;
  left: 91%;
  animation-duration: 73s;
  animation-delay: -171s;
  transform-origin: -16vw -12vh;
  box-shadow: -2vmin 0 0.544736906348465vmin currentColor;
}
.background span:nth-child(45) {
  color: #ffffff;
  top: 64%;
  left: 98%;
  animation-duration: 174s;
  animation-delay: -55s;
  transform-origin: 24vw -24vh;
  box-shadow: -2vmin 0 0.5008945112450565vmin currentColor;
}
.background span:nth-child(46) {
  color: #ffffff;
  top: 84%;
  left: 7%;
  animation-duration: 88s;
  animation-delay: -103s;
  transform-origin: 25vw 7vh;
  box-shadow: -2vmin 0 0.2564248282930712vmin currentColor;
}
.background span:nth-child(47) {
  color: #ffffff;
  top: 69%;
  left: 80%;
  animation-duration: 163s;
  animation-delay: -139s;
  transform-origin: 1vw -16vh;
  box-shadow: 2vmin 0 0.42328697520064473vmin currentColor;
}
.background span:nth-child(48) {
  color: #ffffff;
  top: 38%;
  left: 25%;
  animation-duration: 193s;
  animation-delay: -158s;
  transform-origin: -8vw -4vh;
  box-shadow: 2vmin 0 0.7562889736084857vmin currentColor;
}
.background span:nth-child(49) {
  color: #ffffff;
  top: 6%;
  left: 64%;
  animation-duration: 183s;
  animation-delay: -214s;
  transform-origin: -20vw -20vh;
  box-shadow: -2vmin 0 0.5931588811332205vmin currentColor;
}